import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridCallbackDetails, GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import { useForm } from "react-hook-form";
import { skipToken } from "@reduxjs/toolkit/query";
import { DateTime } from "luxon";
import { EventLabelAbstractFormInput} from "../../components/EventAnalytics/Modals/EventLabelAbstractModal";
import colors from "../../colors";
import AnalyticsReconciliation from "./AnalyticsReconciliation";
import { useAppAnalyticsEventsListQuery } from "../../store/cabinetApi/generated/analytics";
import {
  AnalyticsETLLogAction, AnalyticsEvent, ExecutiveContact, Leader, RootState, ThunkDispatchType
} from "../../store";
import { 
  fetchAnalyticsAnalysisInclusions,
  fetchAnalyticsEventCategories, fetchAnalyticsEventsStateless, fetchAnalyticsEventTypes,
  fetchConsolidateLocations,
  fetchRecurrenceTypes,
  createAnalyticsEventCategory,
  createAnalyticsEventType,
  resyncAnalyticsEvents,
  updateAnalyticsEvent
} from "../../store/eventAnalytics/actions";
import { getLeadersForScheduling } from "../../utils/leaderUtils";
import { useMountEffect } from "../../utils/hooks";
import { isEqual } from "lodash-es";


const pageSize = 20;


const AnalyticsReconciliationContainer = (): ReactElement => {
  const user = useSelector((state: RootState) => (state.auth.user));
  const leaders = useSelector((state: RootState) => (state.leaders));
  const event_categories = useSelector((state: RootState) => state.eventAnalytics.event_categories);
  const event_types = useSelector((state: RootState) => state.eventAnalytics.event_types);
  const consolidated_locations = useSelector((state: RootState) => state.eventAnalytics.consolidated_locations);
  const executive_contacts = useSelector((state: RootState) => state.eventAnalytics.executive_contacts_reconciliation);
  const recurrence_types = useSelector((state: RootState) => (state.eventAnalytics.recurrence_types));
  const meta = useSelector((state: RootState) => state.eventAnalytics.meta);
  const analysis_inclusions = useSelector((state: RootState) => state.eventAnalytics.analysis_inclusions);
  const isTrialing = useSelector((state: RootState) => state.organization.trialing);

  const dispatch = useDispatch<ThunkDispatchType>();

  const [selectedLeader, setSelectedLeader] = useState<Leader | null>(null);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [dataGridQuery, setDataGridQuery] = useState<Record<string, string | string[] | undefined>>({});
  const [exportData, setExportData] = useState<
  {data: {[key: number]: AnalyticsEvent}, meta: {contacts: {[key: number]: ExecutiveContact}}} | undefined
  >();
  const [page, setPage] = useState(0);
  const [resyncFetched, setResyncFetched] = useState(false);
  const [eventForCreateCategory, setEventForCreateCategory] = useState<Partial<AnalyticsEvent> | null>(null);
  const [eventForCreateType, setEventForCreateType] = useState<Partial<AnalyticsEvent> | null>(null);
  const [recurringMeetingData, setRecurringMeetingData] = useState<
  Partial<AnalyticsEvent> & Pick<AnalyticsEvent, 'id'
  > | null>(null);

  const { control, reset, getValues, formState } = useForm<EventLabelAbstractFormInput>({
    defaultValues: {
      id: -1,
      name: "",
      color: colors.black200,
      organization: user?.active_license.organization
    }
  });

  const start = typeof dataGridQuery["start_date"] === "string"
    ? DateTime.fromISO(dataGridQuery["start_date"])
    : undefined;
  const end = typeof dataGridQuery["end_date"] === "string"
    ? DateTime.fromISO(dataGridQuery["end_date"])
    : undefined;

  const { data: analyticsEvents, isFetching, refetch } = useAppAnalyticsEventsListQuery(
    (Array.isArray(dataGridQuery.leader) && dataGridQuery.leader.length > 0 && start && end)
      ? {
        limit: pageSize, offset: page * pageSize,
        ...dataGridQuery,
        startDate: start.toISO() || undefined,
        endDate: end.toISO() || undefined,
      }
      : skipToken
  );

  const events = useMemo(() => (
    analyticsEvents?.data.events as Record<number, AnalyticsEvent> || {}
  ), [analyticsEvents?.data.events]);

  const events_order = useMemo(() => (
    analyticsEvents?.meta.id_order as number[] || []
  ), [analyticsEvents?.meta.id_order]);

  const maxDate = analyticsEvents?.meta.max_end_date ? DateTime.fromISO(analyticsEvents.meta.max_end_date) : null;
  const minDate = analyticsEvents?.meta.min_start_date ? DateTime.fromISO(analyticsEvents.meta.min_start_date) : null;
  const etlStatus = analyticsEvents?.meta.most_recent_log as AnalyticsETLLogAction || AnalyticsETLLogAction.NOT_FETCHED;

  const myLeaders = useMemo(() => getLeadersForScheduling(leaders.leaders), [leaders.leaders]);

  const fetchAnalyticsReconciliationExport = useCallback(async () => {
    let complete = false;
    setExportData(undefined);
    if (dataGridQuery["start_date"] && dataGridQuery["end_date"] && dataGridQuery["leader"]) {
      setLoading(true);
      complete = await dispatch(fetchAnalyticsEventsStateless(dataGridQuery)).then(res => {
        if (res.status === 200) {
          setExportData(res.data);
        } else {
          setExportData(undefined);
        }
        return true;
      });
      setLoading(false);
    }
    return complete;
    
  }, [dataGridQuery, dispatch]);

  const handleChangeDates = (startDate: DateTime | undefined, endDate: DateTime | undefined) => {
    setDataGridQuery({...dataGridQuery, start_date: startDate?.toISO() || "", end_date: endDate?.toISO() || ""});
  };

  useEffect(() => {
    if (selectedLeader === null) {
      setSelectedLeader(myLeaders[0] || null);
    }
  // ignore selectedLeader because it will trigger this effect every time it changes    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLeaders]);

  useEffect(() => {
    if (selectedLeader && !isEqual(dataGridQuery["leader"], [selectedLeader?.id.toString()])) {
      setDataGridQuery({...dataGridQuery, leader: [selectedLeader?.id.toString()]});
    }
  }, [dataGridQuery, selectedLeader]);

  useMountEffect(() => {
    setInitialLoading(true);
    const initialFetch = async () => {
      const locations_res = dispatch(fetchConsolidateLocations());
      const recurrenceRes = dispatch(fetchRecurrenceTypes());
      const category_res = dispatch(fetchAnalyticsEventCategories());
      const types_res = dispatch(fetchAnalyticsEventTypes());
      const inclusions_res = dispatch(fetchAnalyticsAnalysisInclusions());
      await Promise.all([locations_res, category_res, types_res, recurrenceRes, inclusions_res]);
      setInitialLoading(false);
    };
    initialFetch();
  });

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const dataSort = sortModel.map(model => `${model.sort === "asc" ? "" : "-"}${model.field}`);
    setDataGridQuery({ ...dataGridQuery, order_by: dataSort });
  }, [dataGridQuery]);

  const handleFilterModelChange = useCallback((model: GridFilterModel, details: GridCallbackDetails) => {
    const currentQuery = { ...dataGridQuery };
    delete currentQuery["attendees"];
    delete currentQuery["title"];
    delete currentQuery["attendee_lookup"];
    delete currentQuery["inclusion"];
    delete currentQuery["event_category"];
    delete currentQuery["event_type"];
    delete currentQuery["consolidated_location"];
    delete currentQuery["recurrence_type"];
    currentQuery["topology"] = Array.isArray(model.logicOperator) ? model.logicOperator[0] : model.logicOperator;
    model.items.forEach(filter => {
      if (filter.value !== undefined) {
        currentQuery[filter.field] = Array.isArray(filter.value) ? filter.value : [filter.value];
      }
    }); 
    setDataGridQuery(currentQuery);
  }, [dataGridQuery]);

  const handleSearchInputChange = useCallback((value: string | null) => {
    if (value !== null) {
      setDataGridQuery({ ...dataGridQuery, search: value });
    }
  }, [dataGridQuery]);

  const updateEvent = useCallback(async (event: Partial<AnalyticsEvent> & Pick<AnalyticsEvent, 'id'>) => {
    if (event?.event_category === -2) {
      setEventForCreateCategory(event);
    } else if (event?.event_type === -2) {
      setEventForCreateType(event);
    } else {
      if (event.recurring_root_event_idr) {
        setRecurringMeetingData(event);
      } else {
        setLoading(true);
        await dispatch(updateAnalyticsEvent(event, false));
        await refetch();
        setLoading(false);
      }
    }
  }, [dispatch, refetch]);

  const cancelCreateLabel = (callback: () => void) => {
    callback();
    reset();
  };

  const createLabel = useCallback((action: "event_type" | "event_category", callback: () => void) => {
    if (action === "event_category") {
      dispatch(createAnalyticsEventCategory(getValues())).then(res => {
        if (res.status === 201) {
          if (eventForCreateCategory !== null && eventForCreateCategory.id) {
            updateEvent(
              { id: eventForCreateCategory.id, event_category: res.data.id, leader: eventForCreateCategory.leader, 
                recurring_root_event_idr: eventForCreateCategory.recurring_root_event_idr 
              });
          }
          callback();
          reset();
        }
      });
    } else if (action === "event_type") {
      dispatch(createAnalyticsEventType(getValues())).then(res => {
        if (res.status === 201) {
          if (eventForCreateType !== null && eventForCreateType.id) {
            updateEvent(
              { id: eventForCreateType.id, event_type: res.data.id, leader: eventForCreateType.leader, 
                recurring_root_event_idr: eventForCreateType.recurring_root_event_idr 
              });
          }
          callback();
          reset();
        }
      });
    }
  }, [dispatch, eventForCreateCategory, eventForCreateType, getValues, reset, updateEvent]);

  const rows: AnalyticsEvent[] = useMemo(
    () => events_order.map((event_id: number) => events[event_id]),
    [events, events_order]);

  const confirmRecurringMeetingChange = useCallback(
    async (event: Partial<AnalyticsEvent> & Pick<AnalyticsEvent, 'id'> | null, updateAll: boolean) => {
      if (event) {
        setLoading(true);
        setRecurringMeetingData(null);
        const response = await dispatch(updateAnalyticsEvent(event, updateAll));
        if (response.status !== 200) {
          setPage(0);
        } else {
          refetch();
        }
        setLoading(false);
      }
    }, [dispatch, refetch]);

  const requestResyncData = useCallback(() => {
    dispatch(resyncAnalyticsEvents());
    return true;
  }, [dispatch]);

  const handleRequestResyncData = useCallback(() => {
    requestResyncData();
    setResyncFetched(true);
  }, [requestResyncData]);

  const mostRecentQueuedDate = useMemo(() => meta.most_recent_queued_log_date ? 
    DateTime.fromISO(meta.most_recent_queued_log_date) :
    null, [meta.most_recent_queued_log_date]);

  const resyncDisabled = useMemo(() => {
    const now = DateTime.now();
    return (
      isTrialing || (mostRecentQueuedDate && (now < mostRecentQueuedDate.plus({ minutes: 60 })))
    ) || loading;
  }, [isTrialing, loading, mostRecentQueuedDate]);

  return (
    <AnalyticsReconciliation
      resyncFetched={resyncFetched}
      myLeaders={myLeaders}
      confirmRecurringMeetingChange={confirmRecurringMeetingChange}
      createLabel={createLabel}
      cancelCreateLabel={cancelCreateLabel}
      handlePageChange={setPage}
      handleSearchInputChange={handleSearchInputChange}
      handleFilterModelChange={handleFilterModelChange}
      handleSortModelChange={handleSortModelChange}
      handleUpdateEvent={updateEvent}
      handleSetLeader={setSelectedLeader}
      handleChangeDates={handleChangeDates}
      onRequestResyncData={handleRequestResyncData}
      resyncDisabled={resyncDisabled}
      start={start}
      end={end}
      user={user}
      event_types={event_types}
      event_categories={event_categories}
      executive_contacts={executive_contacts}
      selectedLeader={selectedLeader}
      consolidated_locations={consolidated_locations}
      loading={loading}
      page={page}
      totalRows={analyticsEvents?.meta?.count || 0}
      maxDate={maxDate || undefined}
      minDate={minDate || undefined}
      eventForCreateCategory={eventForCreateCategory}
      setEventForCreateCategory={setEventForCreateCategory}
      eventForCreateType={eventForCreateType}
      setEventForCreateType={setEventForCreateType}
      control={control}
      recurringMeetingData={recurringMeetingData}
      recurrence_types={recurrence_types}
      rows={rows}
      setRecurringMeetingData={setRecurringMeetingData}
      updateEvent={updateEvent}
      isValid={formState.isValid}
      exportData={exportData}
      meta={meta}
      fetchAnalyticsReconciliationExport={fetchAnalyticsReconciliationExport}
      analysis_inclusions={analysis_inclusions}
      etlStatus={etlStatus}
      initialLoading={initialLoading}
      isTrialing={isTrialing}
    />
  );
};

export default AnalyticsReconciliationContainer;
